import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  mdiChartLine,
  mdiSale,
  mdiCashMultiple,
  mdiAccount,
  mdiCog,
  mdiInformation,
  mdiLaptop,
  mdiFileDocument,
  mdiBullhorn,
	mdiHomeAnalytics,
} from '@mdi/js';
import {
  filter, map, tap, shareReplay, switchMap,
} from 'rxjs/operators';
import {
  combineLatest,
  Observable,
  of,
  Subscription,
  BehaviorSubject,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService, ProfileState } from './api/user.service';
import { Permission } from '../entities/user-permission';
import { UserRole } from '../entities/user-role';
import { camcase } from '../entities/util';
import { AdvertsService } from '../modules/adverts/adverts.service';

export interface IOption {
  name: string;
  icon?: string;
  mdiClass?: string;
  svg?: string;
  routerLink?: string[];
  event?: string;
  children?: IOption[];
  permissionsReq?: Permission[]; // match ANY of these requirements
  /**
   * This property is used to hide a nav item superficially, i.e. hide it from a user who does
   * technically have permission to access the function but just needs to de-clutter their nav
   * menu, e.g. admin - who has access to a whole bunch of functions but only really uses a subset
   * thereof regularly
   */
  hideFor?: UserRole[];
  faEvent?: string;
  slug?: string;
  badgeCount?: number;
  /** Text to display with the link */
  att?: string;
  /**
   * A flag that indicates that the additional logic (over and above `hideFor`) that influences
   * whether this nav option should be shown have succeeded
   */
  extraVisibilityChecksPass?: boolean,
}

const r = UserRole;

@Injectable({
  providedIn: 'root',
  })
export class NavService implements OnDestroy {
  private readonly navigationOptions: IOption[] = [
    {
      name: 'Group Dashboard',
      mdiClass: 'mdi mdi-home-analytics',
      svg: mdiHomeAnalytics,
      routerLink: ['/group-dashboard'],
      permissionsReq: [Permission.GroupAnalyticsView],
      hideFor: [UserRole.Admin, UserRole.SalesHead, UserRole.FinanceManager],
      faEvent: 'tap_group_analytics_menu_item',
    },
    {
      name: 'Dashboard',
      mdiClass: 'mdi mdi-google-analytics',
      svg: mdiChartLine,
      routerLink: ['/dashboard'],
      permissionsReq: [Permission.AnalyticsView, Permission.GroupAnalyticsView],
      hideFor: [UserRole.Admin, UserRole.SalesHead, UserRole.FinanceManager],
      faEvent: 'tap_analytics_menu_item',
			slug: 'indiedashboard'
    },
    {
      name: 'Orders',
      mdiClass: 'mdi mdi-shopping',
      svg: mdiChartLine,
      routerLink: ['/orders/list'],
      permissionsReq: [Permission.AnalyticsView, Permission.GroupAnalyticsView],
      hideFor: [
				UserRole.Admin,
				UserRole.SalesHead,
				UserRole.FinanceManager,
			],
      faEvent: 'tap_orders_menu_item',
    },
    {
      name: 'Promotions',
      mdiClass: 'mdi mdi-sale',
      svg: mdiSale,
      routerLink: ['/promos'],
      permissionsReq: [Permission.PromoView],
      hideFor: [r.Admin, r.SalesHead, r.FinanceManager, r.RestManagerLesser],
      faEvent: 'tap_promos_menu_item',
    },
    {
      name: 'Advertising',
      mdiClass: 'mdi mdi-bullhorn',
      svg: mdiBullhorn,
      routerLink: ['/advertising'],
      permissionsReq: [Permission.AdvertsView],
      att: 'New',
      extraVisibilityChecksPass: false,
    },
    {
      name: 'Campaigns',
      mdiClass: 'mdi mdi-sale',
      svg: mdiSale,
      routerLink: ['/grocery-campaigns'],
      permissionsReq: [Permission.GroceryCampaignView],
    },
    {
      name: 'Reviews',
      mdiClass: 'mdi mdi-star-circle',
      svg: mdiSale,
      routerLink: ['/orders', 'reviews'],
      permissionsReq: [Permission.AnalyticsView, Permission.GroupAnalyticsView],
      hideFor: [
				UserRole.Admin,
				UserRole.SalesHead,
				UserRole.FinanceManager,
				UserRole.GroupHead
			],
      faEvent: 'tap_promos_menu_item',
    },
    {
      name: 'Payments',
      mdiClass: 'mdi mdi-cash-multiple',
      svg: mdiCashMultiple,
      routerLink: ['/payments'],
      permissionsReq: [Permission.FinanceView],
      hideFor: [
        r.Admin,
        r.Support,
        r.RestaurantManager,
        r.RestManagerLesser,
				r.GroupHead,
				r.FranchiseHead,
      ],
    },
    {
      name: 'Reports',
      mdiClass: 'mdi mdi-file-document',
      svg: mdiFileDocument,
      routerLink: ['/reports'],
      permissionsReq: [Permission.FinanceView, Permission.GroupAnalyticsView],
      hideFor: [UserRole.Admin],
    },
    {
      name: 'Users',
      mdiClass: 'mdi mdi-account',
      svg: mdiAccount,
      routerLink: ['/admin', 'users'],
      permissionsReq: [Permission.UserView, Permission.GroceryUserView, Permission.GroupAnalyticsView],
      faEvent: 'tap_users_menu_item',
    },
    {
      name: 'FAQs',
      mdiClass: 'mdi mdi-information',
      svg: mdiInformation,
      routerLink: ['/faq'],
			permissionsReq: [Permission.UserView, Permission.GroupAnalyticsView],
      hideFor: [UserRole.Admin, UserRole.GroupHead],
    },
    {
      name: 'Restaurant Locations',
      mdiClass: 'mdi mdi-cog',
      svg: mdiCog,
      routerLink: ['/admin', 'restaurant-locations'],
      permissionsReq: [Permission.RestaurantSetView],
			hideFor: [UserRole.GroupHead]
    },
    {
      name: 'Support',
      mdiClass: 'mdi mdi-information',
      svg: mdiInformation,
      routerLink: ['/support'],
      permissionsReq: [Permission.SupportView],
      hideFor: [UserRole.Admin, UserRole.SalesHead],
    },
    {
      name: 'User Bulk Assign',
      mdiClass: 'mdi mdi-laptop',
      svg: mdiLaptop,
      routerLink: ['/admin', 'users-bulk-assign'],
      permissionsReq: [Permission.AdminView],
    },
    {
      name: 'System Config',
      mdiClass: 'mdi mdi-cog',
      svg: mdiCog,
      routerLink: ['/system', 'config'],
      permissionsReq: [Permission.AdminView],
    },
    {
      name: 'Co-funded Promos',
      mdiClass: 'mdi mdi-sale',
      svg: mdiSale,
      routerLink: ['/cofunded-promos'],
      permissionsReq: [Permission.AdminView],
    }, {
      name: 'Promo Moderation',
      mdiClass: 'mdi mdi-list-status',
      svg: mdiSale,
      routerLink: ['/admin', 'promo-moderation'],
      permissionsReq: [Permission.AdminView],
    },
  ];

  private readonly options = new BehaviorSubject<IOption[]>([]);
  get options$() {
    return this.options.asObservable();
  }

  private get profileFilter$(): Observable<IOption[]> {
    return this.user.profile.pipe(
      filter((profileState) => profileState.state === ProfileState.Ready),
      map((profileState) => {
        const isAdmin = profileState.profile.role_name === UserRole.Admin;
        let opts = [...this.navigationOptions]; // clone
        // exclude options the user does not have permission to use
        if (!isAdmin && profileState.profile?.permissions) {
          const perms = profileState.profile.permissions;
          const matchAny = (req: Permission[]) => !!req.find((r) => perms.includes(r));
          opts = opts.filter(
            (opt) => !opt.permissionsReq?.length || matchAny(opt.permissionsReq),
          );
        }
        // exclude options explicitly hidden from the user role
        opts = opts.filter(
          (opt) => !opt.hideFor?.length
            || !opt.hideFor.includes(profileState.profile.role_name),
        );
        return opts;
      }),
      tap((opts) => opts.forEach((opt) => (opt.slug = opt.slug ?? camcase(opt.name)))),
      switchMap((opts) => combineLatest([of(opts), this.user.incentiveCount$])),
      map(([opts, counts]) => {
        opts.forEach((opt) => {
          if (!environment.showBanners) {
            opt.badgeCount = 0;
          } else if (opt.slug in counts) {
            opt.badgeCount = counts[opt.slug];
          }
        });
        return opts;
      }),
      shareReplay(),
    );
  }

  private profileSub: Subscription;
  private advertsSub: Subscription;

  constructor(
    private readonly router: Router,
    private readonly user: UserService,
    private readonly advertsAPI: AdvertsService,
  ) {
    this.profileSub = this.profileFilter$.subscribe((opts) => this.emitOptions(opts));
    this.addAdvertsBasedOnSelectedRestaurant();
  }

  addAdvertsBasedOnSelectedRestaurant() {
    this.advertsSub = combineLatest([
      this.advertsAPI.allowed$,
      this.profileFilter$,
    ]).subscribe(([allowed, options]) => {
      const advertisingNavItem = options.find((opt) => opt.name === 'Advertising');
      if (!advertisingNavItem) return;

      advertisingNavItem.extraVisibilityChecksPass = allowed;

      this.emitOptions(options);

      if (!advertisingNavItem.extraVisibilityChecksPass) {
        if (/advertising/.test(this.router.url)) {
          this.router.navigate(['/dashboard']);
        }
      }
    });
  }

  /**
   * Emits the subset of `navigationOptions` that is appropriate for the current user.  This will
   * always be nav options that have passed the basic universal checks and either (a) don't have
   * any more conditions to satisfy or (b) do have extra conditions to satisfy and they have met
   * them.
   * @param options A list of all the navigation options that are valid for the current user
   */
  emitOptions(options: IOption[]) {
    this.options.next(
      options.filter((opt) => !('extraVisibilityChecksPass' in opt)
        || opt.extraVisibilityChecksPass),
    );
  }

  ngOnDestroy(): void {
    this.profileSub?.unsubscribe();
    this.advertsSub?.unsubscribe();
  }
}
